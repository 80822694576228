import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Modal,
  Row,
} from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import API from "../../../api/api";
import { useToast } from "../../../contexts/toast";
import CRUDPageGRIDCustom from "../../../pages/crud/CRUDPageGridCustom";
import { dateFormater } from "../../../utils/date-formater";
import { parsedUser } from "../../../utils/GetCurrentUser";
import { USDollar } from "../../../utils/number-to-currency";
import FieldSelector from "../../customComponents/FieldSelector";
import {
  CashierCommentsFields,
  PersonalInfoFields,
  CurrencyFields,
  CollectionFormatFields,
} from "../fields/CashierFields";
import AddFolio from "./AddFolio";
import ApplyAdvance from "./ApplyAdvance";
import ApplyCharge from "./ApplyCharge";
import CashierFilter from "./CashierFilter";
import MakeAjustment from "./MakeAjustment";
import Payment from "./Payment";
import PrintFolio from "./PrintFolio";
import PrintInvoice from "./PrintInvoice";
import Transfer from "./Transfer";
import ReservationDetails from "../reservationDetails/ReservationDetails";
import moment from "moment";
import { GetDataOfJSON } from "../../../utils/GetDataOfJSON";
import RelatedInvoice from "./RelatedInvoice";
import { useTranslation } from "react-i18next";
import CollectionFormatcomponent from "./CollectionFormatcomponent";
import MasterFolioPrincipalBtnGroup from "./MasterFolioPrincipalBtnGroup";
import CRUDPageGridCustomCommentCashier from "../../../pages/crud/CRUDPageGridCustomCommentCashier";

const CashierModal = (props) => {
  const toast = useToast();
  const user = parsedUser();
  const { t } = useTranslation();

  let totalAmountFolios = 0;
  let totalAmountFolioDetails = 0;
  const [isLoading, setIsLoading] = useState(false);
  const [validationsSchema, setValidationSchema] = useState(null);
  const [generalInfo, setGeneralInfo] = useState(null);
  const [folios, setFolios] = useState([]);
  const [showPayment, setShowPayment] = useState(false);
  const fields = PersonalInfoFields;
  const commentFields = CashierCommentsFields;
  const [reservationInfo, setReservationInfo] = useState(null);
  const [selectedFolio, setSelectedFolio] = useState(null);
  const [selectedFolioDetails, setSelectedFolioDetails] = useState([]);
  const [selectedFolioDetail, setSelectedFolioDetail] = useState(null);
  const [showAddFolios, setShowAddFolios] = useState(false);
  const [show, setShow] = useState(false); // For check-out purpouses
  const [actionMode, setActionMode] = useState(""); // For check-out purpouses
  const [selectedItem, setSelectedItem] = useState(null); // For check-out purpouses
  const [currencySelected, setCurrencySelected] = useState(null);
  const [collectionFormatselected, setCollectionFormatSelected] = useState("R");

  const filterRef = useRef(null);
  const MasterFolioRef = useRef(null);

  const handleCurrencyChange = (fieldName, fieldValue) => {
    setCurrencySelected(fieldValue);
    if (reservationInfo && (reservationInfo.Room || reservationInfo.ReservationNumber || reservationInfo.GUIDReservation)) {
      filterRef.current?.submit();
    }
    setSelectedFolioDetails([]);
    setSelectedFolio(null);
  };

  const handleCollectionFormatChange = (fieldName, fieldValue) => {
    setSelectedFolio(null);
    setSelectedFolioDetails([]);
    setReservationInfo(null);
    setFolios([]);
    setGeneralInfo(null);
    setCollectionFormatSelected(fieldValue);
    formik.resetForm();
    // filterRef.current?.submit();
  }

  const closePayment = () => {
    setShowPayment(false);
    setSelectedFolioDetails([]);
    if (selectedFolio) {
      searchFolioDetails(
        selectedFolio,
        reservationInfo.Room,
        reservationInfo.ReservationNumber
      );
    }
  };

  const togglePayment = () => {
    const selectedFolioDetail = selectedFolioDetails.filter(
      (x) => x.AllowPayment === false
    )[0];
    if (selectedFolioDetail) {
      toast({
        message: t("This record cannot be paid"),
        type: "error",
        height: "auto",
        width: "auto",
      });
      return;
    }

    setShowPayment(!showPayment);
    if (showPayment) {
      search(
        reservationInfo.Room,
        reservationInfo.ReservationNumber,
        reservationInfo.GUID
      );
      if (selectedFolio) {
        searchFolioDetails(
          selectedFolio,
          reservationInfo.Room,
          reservationInfo.ReservationNumber
        );
      }
    }
  };

  const toggleCharge = () => {
    setShowCharge(!showCharge);
  };

  const toggleAddFolios = () => {
    setShowAddFolios(!showAddFolios);
    if (showAddFolios) {
      search(
        reservationInfo.Room,
        reservationInfo.ReservationNumber,
        reservationInfo.GUID
      );
      if (selectedFolio) {
        searchFolioDetails(
          selectedFolio,
          reservationInfo.Room,
          reservationInfo.ReservationNumber
        );
      }
    }
  };
  const formik = useFormik({
    initialValues: {},
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validationsSchema,
    onSubmit: () => { },
  });

  const [showCharge, setShowCharge] = useState(false);
  const toggleApplyCharge = () => {
    setShowCharge(!showCharge);

    if (showCharge) {
      search(
        reservationInfo.Room,
        reservationInfo.ReservationNumber,
        reservationInfo.GUID
      );
      if (selectedFolio) {
        searchFolioDetails(
          selectedFolio,
          reservationInfo.Room,
          reservationInfo.ReservationNumber
        );
      }
    }
  };

  const [showAdvance, setShowAdvance] = useState(false);
  const toggleApplyAdvance = () => {
    setShowAdvance(!showAdvance);

    if (showAdvance) {
      search(
        reservationInfo.Room,
        reservationInfo.ReservationNumber,
        reservationInfo.GUID
      );
      if (selectedFolio) {
        searchFolioDetails(
          selectedFolio,
          reservationInfo.Room,
          reservationInfo.ReservationNumber
        );
      }
    }
  };
  const [showPrintInvoice, setShowPrintInvoice] = useState(false);
  const togglePrintInvoice = (obj) => {
    setSelectedFolioDetail(obj);
    setShowPrintInvoice(!showPrintInvoice);
  };

  const [showPrintFolio, setShowPrintFolio] = useState(false);
  const togglePrintFolio = () => {
    setShowPrintFolio(!showPrintFolio);
  };

  const [showAjustment, setShowAjustment] = useState(false);
  const toggleAjustment = (item) => {
    console.log("🚀 ~ toggleAjustment ~ item:", item);
    setSelectedFolioDetail(item);
    setShowAjustment(!showAjustment);

    if (showAjustment) {
      search(
        reservationInfo.Room,
        reservationInfo.ReservationNumber,
        reservationInfo.GUID
      );
      if (selectedFolio) {
        searchFolioDetails(
          selectedFolio,
          reservationInfo.Room,
          reservationInfo.ReservationNumber
        );
      }
    }
  };

  const updateField = (fieldName, fieldValue) => {
    formik.setFieldValue(fieldName, fieldValue);
  };

  const principalButtonList = [
    {
      id: 3,
      title: "Check Out",
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-calendar-check",
      ifSelectedItem: false,
      reservationCode: [],
      onClick: () => {
        console.log("PRESSED CHECKOUT BUTTON");
        toggleCheckout();
      },
      reservationSelected: true,
      folioDetailSelected: false,
      folioSelected: false,
    },
    {
      id: 1,
      title: "Charge",
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-calculator",
      ifSelectedItem: false,
      reservationCode: [],
      onClick: () => toggleApplyCharge(),
      folioDetailSelected: false,
      folioSelected: true,
    },
    {
      id: 1,
      title: "Deposit",
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-calculator",
      ifSelectedItem: false,
      reservationCode: [],
      onClick: () => toggleApplyAdvance(),
      folioDetailSelected: false,
      folioSelected: true,
    },
    {
      id: 2,
      title: "Invoice",
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-money-check-dollar",
      ifSelectedItem: false,
      reservationCode: [],
      onClick: () => {
        console.log("Click on Invoice");
        togglePayment();
      },
      folioDetailSelected: true,
      folioSelected: true,
    },
    {
      id: 2,
      title: "Print Folio",
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-print",
      ifSelectedItem: false,
      reservationCode: [],
      onClick: () => {
        togglePrintFolio();
      },
      folioDetailSelected: false,
      folioSelected: true,
    },
    {
      id: 5,
      title: "Transfer",
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-arrow-right-arrow-left",
      ifSelectedItem: false,
      reservationCode: [],
      onClick: () => {
        console.log("Transfer");
        toggleTransfer();
      },
      folioDetailSelected: true,
      folioSelected: true,
    },
  ];

  const handleCloseReservationDetails = () => {
    setShow(false);
    setSelectedItem(null);
  };

  const checkOutValidation = () => {
    //TODO: Add validation for check out
    toggleCheckout("O");
  };

  const handleFilter = async (obj) => {
    setSelectedFolioDetails([]);
    setFoliosDetails([]);
    pickReservation(obj);
    return;
  };

  const handleClose = () => {
    props.setShow(false);
  };

  const [showCancelled, setShowCancelled] = useState(false);
  const [foliosDetails, setFoliosDetails] = useState([]);

  const handleCheckboxChange = (event, id) => {
    console.log(event.target.checked, id);
    const exist = selectedFolioDetails.filter(
      (rowId) => rowId.TransactionDate === id.TransactionDate
    )[0];
    console.log(id, exist, selectedFolioDetails);
    if (!exist) {
      setSelectedFolioDetails([...selectedFolioDetails, id]);
    } else {
      setSelectedFolioDetails(
        selectedFolioDetails.filter(
          (rowId) => rowId.TransactionDate !== id.TransactionDate
        )
      );
    }
  };

  const search = async (room, reservationNumber, reservationGUID) => {
    // setSelectedFolioDetails([]);
    // console.log(reservationInfo);
    let query = `IdUser=${user.IdUser}&token=${user.Token}`;
    if (reservationNumber) {
      room = null;
    } else {
      reservationNumber = null;
    }
    let queryData = {
      Data: `@Room=${room ? "'" + room + "'" : "null"}, @ReservationNumber=${reservationNumber ? reservationNumber : "null"
        }, @GUIDReservation=${reservationGUID ? "'" + reservationGUID + "'" : "null"
        }, @IdBusinessUnit=${user.IdBusinessUnit}, @IdCurrency=${currencySelected ? currencySelected : "null"
        }, @Accion='L', @RecordSource='R'`,
    };
    // let prefix = idModule ? "CRUDDynamic" : "CRUD";
    let request = await API.postAction(
      `api/cashier/SearchReservation?` + query,
      queryData ?? ""
    );

    let results = request.data[0];
    let parsedResults = JSON.parse(results.JSONData)[0];
    console.log({ JSONData: parsedResults })
    if (parsedResults.Error) {
      confirmAlert({
        closeOnClickOutside: true,
        message: parsedResults.Msg,
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
      //toast({ message: parsedResults.Msg, type: 'error' });
      return;
    }
    console.log(parsedResults)
    let record = parsedResults.Record && parsedResults.Record[0];
    console.log("🚀 ~ search ~ record:", record);


    setFolios(parsedResults.Data || []);
    console.log(parsedResults.Data)

    //Select first folio if have any 
    if (parsedResults.Data && parsedResults.Data.length > 0) {
      setSelectedFolio(parsedResults.Data[0]);
    }
    setGeneralInfo(record);
    Object.keys(record).forEach((item) => {
      updateField(item, record[item]);
    });
  };

  const [showTransfer, setShowTransfer] = useState(false);
  const toggleTransfer = () => {
    setShowTransfer(!showTransfer);
    if (showTransfer) {
      search(
        reservationInfo.Room,
        reservationInfo.ReservationNumber,
        reservationInfo.GUID
      );
      if (selectedFolio) {
        searchFolioDetails(
          selectedFolio,
          reservationInfo.Room,
          reservationInfo.ReservationNumber
        );
      }
    }
  };

  const reverse = async (obj) => {
    confirmAlert({
      message: "Are you sure you want to reverse this record?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            // console.log(obj);
            // debugger;
            let query = `token=${user.Token}&IdUser=${user.IdUser}`;
            let queryData = {
              Data: `@IdReservationToFolioDetail=${obj.IdReservationToFolioDetail}`,
            };

            let requestAPI = await API.postAction(
              "api/cashier/reverse?" + query,
              queryData
            );

            let parsedResults = GetDataOfJSON(
              requestAPI,
              "data",
              0,
              "JSONData",
              0
            );

            if (parsedResults.Error) {
              toast({
                message: parsedResults.Msg,
                type: "error",
                height: "auto",
                width: "auto",
              });
            } else {
              await search(
                reservationInfo.Room,
                reservationInfo.ReservationNumber,
                reservationInfo.GUIDReservation
              );
              if (selectedFolio) {
                await searchFolioDetails(
                  selectedFolio,
                  reservationInfo.Room,
                  reservationInfo.ReservationNumber
                );
              }
            }
          },
        },
        { label: "No" },
      ],
    });
  };

  const nullInvoice = async (obj) => {
    confirmAlert({
      message: "Are you sure you want to null this invoice?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            console.log(obj);

            let query = `token=${user.Token}&IdUser=${user.IdUser}`;
            let queryData = {
              Data: `@GUIDBillingDocument ='${obj.GUIDBillingDocument}'`,
            };

            await API.postAction("api/cashier/nullInvoice?" + query, queryData);
            console.log(reservationInfo);
            await search(
              reservationInfo.Room,
              reservationInfo.ReservationNumber,
              reservationInfo.GUIDReservation
            );
            if (selectedFolio) {
              await searchFolioDetails(
                selectedFolio,
                reservationInfo.Room,
                reservationInfo.ReservationNumber
              );
            }
          },
        },
        { label: "No" },
      ],
    });
  };

  const searchFolioDetails = async (folio, room, reservationNumber) => {
    // console.log("🚀 ~ searchFolioDetails ~ generalInfo:", generalInfo);
    // console.log(
    //   "🚀 ~ searchFolioDetails ~ currencySelected:",
    //   currencySelected
    // );
    try {
      // console.log(reservationInfo);
      let query = `IdUser=${user.IdUser}&token=${user.Token}`;

      let queryData = {
        Data: `@Room=${room && room !== "Pending" ? "'" + room + "'" : "null"
          }, @ReservationNumber=${reservationNumber ? "'" + reservationNumber + "'" : "null"
          }, @GUIDReservation=${reservationInfo.GUIDReservation
            ? "'" + reservationInfo.GUIDReservation + "'"
            : "null"
          }, @GUIDReservationToFolio =${folio.GUIDReservationToFolio
            ? "'" + folio.GUIDReservationToFolio + "'"
            : "null"
          },@IdBusinessUnit=${user.IdBusinessUnit}, @IdCurrency=${currencySelected ? currencySelected : "null"
          }`,
      };
      // let prefix = idModule ? "CRUDDynamic" : "CRUD";
      let request = await API.postAction(
        `api/cashier/FolioDetails?` + query,
        queryData ?? ""
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0];
      console.log("🚀 ~ searchFolioDetails ~ parsedResults:", parsedResults);
      // console.log(parsedResults);
      if (parsedResults.Error) {
        confirmAlert({
          closeOnClickOutside: true,
          message: parsedResults.Msg,
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
        //toast({ message: parsedResults.Msg, type: 'error' });
        return;
      }

      setFoliosDetails(parsedResults?.Data || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    console.log(generalInfo, selectedFolio);
    console.log('Loaded folios', folios);
    if (selectedFolio && collectionFormatselected === 'R') {
      searchFolioDetails(
        selectedFolio,
        generalInfo.Room,
        generalInfo.ReservationNumber
      );
    } else {
      setFoliosDetails([]);
    }
  }, [selectedFolio]);

  const selectAllFolioDetails = () => {
    const allowedToBePaid = foliosDetails.filter(
      (x) => x.AllowPayment === true
    );
    setSelectedFolioDetails(allowedToBePaid);
  };

  const pickReservation = (obj) => {
    const { GUID, Room, ReservationNumber } = obj;
    setSelectedItem(obj);
    setReservationInfo({
      Room: Room,
      ReservationNumber: ReservationNumber,
      GUIDReservation: GUID,
    });
    search(Room ?? "", ReservationNumber ?? "", GUID);
  }

  useEffect(() => {
    if (props.reservation && collectionFormatselected === 'R') {
      pickReservation(props.reservation);
    }
  }, [props.reservation]);

  const [showCheckout, setShowCheckout] = useState(false);
  const toggleCheckout = async () => {
    confirmAlert({
      message: "Are you sure you want to check out?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setShowCheckout(!showCheckout);
            if (showCheckout) {
              search(
                reservationInfo.Room,
                reservationInfo.ReservationNumber,
                reservationInfo.GUID
              );
              if (selectedFolio) {
                searchFolioDetails(
                  selectedFolio,
                  reservationInfo.Room,
                  reservationInfo.ReservationNumber
                );
              }
            }
          },
        },
        { label: "No" },
      ],
    });

    // if (!showCheckout) {
    //   // check first if we can request checkout
    //   console.log(reservationInfo);
    //   let query = `IdUser=${user.IdUser}&token=${user.Token}`;

    //   let queryData = {
    //     Data: `@GUIDReservation=${reservationInfo.GUIDReservation
    //       ? "'" + reservationInfo.GUIDReservation + "'"
    //       : "null"
    //       }`,
    //   };
    //   // let prefix = idModule ? "CRUDDynamic" : "CRUD";
    //   let request = await API.postAction(
    //     `api/cashier/CanDoCheckout?` + query,
    //     queryData ?? ""
    //   );
    //   console.log(request);
    //   if (request.status === 200) {
    //     let records = request.data[0];
    //     let parsedRecords = JSON.parse(records.JSONData)[0];
    //     console.log(parsedRecords);
    //     if (parsedRecords.Error) {
    //       confirmAlert({
    //         closeOnClickOutside: true,
    //         message: parsedRecords.Msg,
    //         buttons: [
    //           {
    //             label: "Ok",
    //           },
    //         ],
    //       });
    //       //toast({ message: parsedRecords.Msg, type: 'error' });
    //       return;
    //     }

    //     if (parsedRecords.Record[0].CheckOutNotAllowed === 1) {
    //       console.log(parsedRecords.Record[0]);
    //       confirmAlert({
    //         closeOnClickOutside: true,
    //         message: parsedRecords.Record[0].Message,
    //         buttons: [
    //           {
    //             label: "Ok",
    //           },
    //         ],
    //       });
    //       return;
    //     }
    //   }
    // }
    setShowCheckout(!showCheckout);
  };

  const [showRelatedInvoice, setShowRelatedInvoice] = useState(false);
  const toggleRelatedInvoice = (obj) => {
    setSelectedFolioDetail(obj);
    setShowRelatedInvoice(true);
  };
  const closeRelatedInvoice = () => {
    setShowRelatedInvoice(false);
  };

  const [masterFolioState, setMasterFolioState] = useState({
    MasterFolioSelected: null,
    MasterFolioDetailSelected: null,
    selectedItem: selectedItem,
    generalInfo: null,
    selectedFolioDetails: [],
    selectedFolio: null,
  })

  const [MasterfolioSelectedItems, setMasterfolioSelectedItems] = useState([]);

  return (
    <>
      {showRelatedInvoice && (
        <RelatedInvoice
          show={showRelatedInvoice}
          close={closeRelatedInvoice}
          selectedFolioDetails={selectedFolioDetail}
        />
      )}
      {/* {showCheckout && (
        <ReservationDetails
          toggle={toggleCheckout}
          show={showCheckout}
          actionMode={"O"}
          selectedReservation={{
            GUID: reservationInfo.ReservationGUID,
          }}
          //       clearSelection={() => setSelectedItem(null)}
          handleClose={toggleCheckout}
        />
      )} */}
      {showAddFolios && (
        <AddFolio
          show={true}
          toggle={toggleAddFolios}
          parent={reservationInfo}
        />
      )}
      {showPrintInvoice && (
        <PrintInvoice
          show={true}
          toggle={togglePrintInvoice}
          selectedFolioDetails={selectedFolioDetail}
        />
      )}
      {showPrintFolio && (
        <PrintFolio
          show={true}
          toggle={togglePrintFolio}
          selectedFolio={selectedFolio}
        />
      )}
      {showAdvance && (
        <ApplyAdvance
          show={true}
          toggle={toggleApplyAdvance}
          parent={reservationInfo}
          selectedFolio={selectedFolio}
        />
      )}
      {showTransfer && (
        <Transfer
          show={true}
          toggle={toggleTransfer}
          parent={selectedFolio}
          currentFolios={folios}
          selectedFolioDetails={selectedFolioDetails}
          selectedReservation={reservationInfo}
          modalSize="lg"
        />
      )}
      {showCharge && (
        <ApplyCharge
          show={showCharge}
          toggle={toggleApplyCharge}
          parent={selectedFolio}
        />
      )}
      {showAjustment && (
        <MakeAjustment
          show={showAjustment}
          toggle={toggleAjustment}
          parent={selectedFolio}
          selectedFolioDetail={selectedFolioDetail}
          IdBillingService={selectedFolioDetail?.IdBillingService}
        />
      )}
      {showPayment && (
        <Payment
          show={showPayment}
          close={closePayment}
          toggle={togglePayment}
          parent={selectedFolio}
          records={selectedFolioDetails}
          reservation={reservationInfo}
          generalInfo={generalInfo}
        />
      )}
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        className="modalRight z-3"
        dialogClassName="modal-90w"
      >
        <Modal.Header closeButton>
          <div className="w-100 d-flex">
            <div className="d-flex my-2">
              <i className="fas fa-cash-register fa-2x me-2"></i>
              <h4>{t("Cashier")}</h4>
            </div>
            {collectionFormatselected === 'R' &&
              <div>
                <CashierFilter
                  ref={filterRef}
                  reservationInfo={reservationInfo}
                  handleFilter={(obj) => handleFilter(obj)}
                  isLoading={isLoading}
                />
              </div>}
          </div>
        </Modal.Header>
        <Modal.Body>
          {collectionFormatselected === 'R'
            && <Container fluid>
              {/* GENERAL INFO */}
              <Row className="border pb-4 mb-4 ">
                <Col xs={12} md={6} className="">
                  <Row>
                    {/* {console.log(generalInfo)} */}
                    {fields
                      .filter((x) => x.Group === 0)
                      .map((item, index) => {
                        return (
                          <Col
                            className={
                              item.cssClasss + (!item.Visible ? " d-none" : "")
                            }
                            key={index}
                          >
                            <label className="me-2 mt-2">{item.FieldTitle}</label>
                            {item.Required ? (
                              <i className="required-asterisk ms-1 text-danger">
                                *
                              </i>
                            ) : null}
                            <br />
                            {item.FieldName === "Room" && (
                              <span
                                className="badge align-content-center px-3 py-2"
                                style={{
                                  backgroundColor: generalInfo
                                    ? generalInfo.BackgroundColorRoom
                                    : "peru",
                                  color: generalInfo
                                    ? generalInfo.TextColorRoom
                                    : "white",
                                  height: "2rem",
                                  fontSize: "1.0rem",
                                }}
                              >
                                {generalInfo?.Room}
                              </span>
                            )}
                            {item.FieldName === "ReservationStatus" && (
                              <span
                                className="badge align-content-center px-3 py-2"
                                style={{
                                  backgroundColor: generalInfo
                                    ? generalInfo.BackgroundColorStatus
                                    : "black",
                                  color: generalInfo
                                    ? generalInfo.TextColorStatus
                                    : "white",
                                  height: "2rem",
                                  fontSize: "1.0rem",
                                }}
                              >
                                {generalInfo?.ReservationStatus}
                              </span>
                            )}
                            <FieldSelector
                              actionMode={props.actionMode}
                              model={item}
                              key={index}
                              obj={formik.values}
                              updateField={updateField}
                              value={formik.values[item.FieldName]}
                            />
                            {formik.errors[item.FieldName] && (
                              <div className="invalid text-sm">
                                {formik.errors[item.FieldName]}
                              </div>
                            )}
                          </Col>
                        );
                      })}
                  </Row>
                </Col>
                <Col xs={12} md={6} className="">
                  <Row>
                    {fields
                      .filter((x) => x.Group === 1)
                      .map((item, index) => {
                        return (
                          <Col
                            className={
                              item.cssClasss + (!item.Visible ? " d-none" : "")
                            }
                            key={index}
                          >
                            <label className="me-2 mt-2">{item.FieldTitle}</label>
                            {item.Required ? (
                              <i className="required-asterisk ms-1 text-danger">
                                *
                              </i>
                            ) : null}
                            <br />
                            {item.FieldName === "Room" && (
                              <span
                                className="badge align-content-center px-3 py-2"
                                style={{
                                  backgroundColor: generalInfo
                                    ? generalInfo.BackgroundColorRoom
                                    : "peru",
                                  color: generalInfo
                                    ? generalInfo.TextColorRoom
                                    : "white",
                                  height: "2rem",
                                  fontSize: "1.0rem",
                                }}
                              >
                                {generalInfo?.Room}
                              </span>
                            )}
                            {item.FieldName === "ReservationStatus" && (
                              <span
                                className="badge align-content-center px-3 py-2"
                                style={{
                                  backgroundColor: generalInfo
                                    ? generalInfo.BackgroundColorStatus
                                    : "black",
                                  color: generalInfo
                                    ? generalInfo.TextColorStatus
                                    : "white",
                                  height: "2rem",
                                  fontSize: "1.0rem",
                                }}
                              >
                                {generalInfo?.ReservationStatus}
                              </span>
                            )}
                            <FieldSelector
                              actionMode={props.actionMode}
                              model={item}
                              key={index}
                              obj={formik.values}
                              updateField={updateField}
                              value={formik.values[item.FieldName]}
                            />
                            {formik.errors[item.FieldName] && (
                              <div className="invalid text-sm">
                                {formik.errors[item.FieldName]}
                              </div>
                            )}
                          </Col>
                        );
                      })}
                  </Row>
                </Col>
              </Row>

              <Row>
                {/* COMMENTS and FOLIOS SECTION*/}
                <Col md={4} xs={12}>
                  {/* FOLIOS */}
                  <Card className="mb-4">
                    <Card.Header>
                      <Card.Title className="me-2 d-inline">Folios</Card.Title>
                      <button
                        className="btn btn-sm btn-secondary"
                        type="button"
                        disabled={!generalInfo}
                        onClick={() => toggleAddFolios()}
                      >
                        <i className="fa-solid fa-plus"></i>
                      </button>
                    </Card.Header>
                    <Card.Body>
                      <Row>
                        <Col>
                          <div className="table-responsive">
                            <table className="table table-bordered table-striped">
                              <thead>
                                <tr>
                                  {/* <th className='text-nowrap text-center'>Select</th> */}
                                  <th className="text-nowrap">{t("Description")}</th>
                                  <th className="text-nowrap text-end">
                                    {t("Total Amount")}{" "}
                                    {generalInfo && generalInfo.CurrencyCode}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {folios && folios.length === 0 && (
                                  <tr>
                                    <td colSpan="2">No records found</td>
                                  </tr>
                                )}
                                {folios &&
                                  folios.length > 0 &&
                                  folios.map((item, index) => {
                                    totalAmountFolios += item.TotalAmount * 1;
                                    // console.log(item);
                                    return (
                                      <tr
                                        key={index}
                                        onClick={() => {
                                          setSelectedFolioDetails([]);
                                          setSelectedFolio(item);
                                        }}
                                        style={{ cursor: "pointer" }}
                                        className={`${item?.AutoInvoiceEnabled && "fw-bold"
                                          }
                                        ${selectedFolio &&
                                          selectedFolio.GUIDReservationToFolio ===
                                          item?.GUIDReservationToFolio &&
                                          "active"
                                          }
                                        ${selectedFolio === item
                                            ? "bg-info"
                                            : ""
                                          }`}
                                      >
                                        <td>{item.FolioType}</td>
                                        <td className="text-end">
                                          {USDollar(item.TotalAmount)}
                                        </td>
                                      </tr>
                                    );
                                  })}
                              </tbody>
                            </table>
                          </div>
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer>
                      <Row>
                        <Col xs={12} className="text-end">
                          {/* {console.log("folios", folios)} */}
                          <span>
                            {t("Grand Total")} {generalInfo && generalInfo.CurrencyCode}:{" "}
                            {USDollar(totalAmountFolios)}
                          </span>
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Card>
                  {/* COmments */}
                  <Row className="border pb-5 rounded-2">
                    <div className="mb-4">
                      {commentFields.map((item, index) => {
                        return (
                          <Col
                            className={
                              item.cssClasss + (!item.Visible ? " d-none" : "")
                            }
                            key={index}
                          >
                            <label className="me-2 mt-2">{item.FieldTitle}</label>
                            {item.Required ? (
                              <i className="required-asterisk ms-1 text-danger">
                                *
                              </i>
                            ) : null}
                            <br />
                            <FieldSelector
                              actionMode={props.actionMode}
                              model={item}
                              key={index}
                              obj={formik.values}
                              updateField={updateField}
                              value={formik.values[item.FieldName]}
                            />
                            {formik.errors[item.FieldName] && (
                              <div className="invalid text-sm">
                                {formik.errors[item.FieldName]}
                              </div>
                            )}
                          </Col>
                        );
                      })}
                    </div>
                    <div>
                      {reservationInfo && (
                        <CRUDPageGridCustomCommentCashier
                          getEndpoint="api/Reservations/ReservationsCommentHistoryRecords"
                          modelEndpoint="api/Reservations/ReservationsCommentHistoryModel"
                          addEditEndpoint="api/Reservations/ReservationsCommentHistoryAddEdit"
                          deleteEndpoint="api/Reservations/ReservationsCommentHistoryDelete"
                          detailsEndpoint="api/Reservations/ReservationsCommentHistoryDetails"
                          headerEndpoint="api/Reservations/ReservationsCommentHistoryHeader"
                          ParentGUID={reservationInfo.GUIDReservation}
                          Parent={reservationInfo}
                          ParentField={"GUIDReservation"}
                          ParentFieldValue={reservationInfo?.GUIDReservation}
                          ParentFieldType={"string"}
                          page={{
                            PageIndex: 2,
                            PageTitle: "Comments",
                            PageIcon: "fa-solid fa-file-lines",
                            MultiRecord: true,
                            Active: true,
                          }}
                        />
                      )}
                    </div>
                  </Row>
                </Col>

                <Col md={8} xs={12}>
                  {/* FOLIOS DETAILS */}
                  <Card className="h-100">
                    <Card.Header>
                      <Card.Title className="d-flex justify-content-between align-items-center m-0">
                        <span>
                          {t("Folio Detail")}
                        </span>
                        <div className="d-flex gap-4">
                          <button
                            className="btn btn-primary"
                            onClick={selectAllFolioDetails}
                          >
                            {t("Select All")}
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() => setSelectedFolioDetails([])}
                          >
                            {t("Unselect All")}
                          </button>
                          <button
                            className="btn btn-outline-danger"
                            onClick={() => {
                              setShowCancelled(!showCancelled);
                            }}
                          >
                            {showCancelled ? t("Hide Cancelled") : t("Show Cancelled")}
                          </button>
                        </div>
                      </Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Row className="h-100">
                        <Col className="table-responsive ">
                          <table className="table table-bordered table-striped">
                            <thead className="table-secondary">
                              <tr>
                                <th
                                  className="text-nowrap text-center align-content-center"
                                  rowSpan={2}
                                >
                                  Select
                                </th>
                                <th
                                  className="text-nowrap align-content-center"
                                  rowSpan={2}
                                >
                                  Date
                                </th>
                                <th
                                  className="text-nowrap text-center align-content-center"
                                  rowSpan={2}
                                >
                                  Transaction Type
                                </th>
                                <th
                                  className="text-nowrap text-center align-content-center"
                                  rowSpan={2}
                                >
                                  Billing Service
                                </th>
                                <th
                                  className="text-nowrap text-center align-content-center"
                                  rowSpan={2}
                                >
                                  References
                                </th>
                                <th
                                  className="text-nowrap text-center align-content-center"
                                  colSpan={2}
                                >
                                  Amount {generalInfo && generalInfo.CurrencyCode}
                                </th>
                                <th
                                  className="text-nowrap text-center align-content-center"
                                  rowSpan={2}
                                >
                                  Billing Status
                                </th>
                                <th
                                  className="text-nowrap text-center align-content-center"
                                  rowSpan={2}
                                ></th>
                              </tr>
                              <tr>
                                <th className="text-nowrap text-center">Total</th>
                                <th className="text-nowrap text-center">
                                  Pending
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {foliosDetails.length > 0 ?
                                foliosDetails
                                  .filter((x) => showCancelled || x.Active) //TODO FIX.
                                  .map((item, index) => {
                                    // console.log({AmountFolioDetails: item.Amount, totals: totalAmountFolioDetails});
                                    if (item.Active) {
                                      totalAmountFolioDetails += item.Amount;
                                    }
                                    let exist =
                                      selectedFolioDetails &&
                                      selectedFolioDetails.filter(
                                        (x) =>
                                          x.TransactionDate ===
                                          item.TransactionDate
                                      )[0];
                                    // console.log(item);
                                    return (
                                      <tr
                                        key={index}
                                        style={{
                                          height: "1rem",
                                          textDecoration:
                                            item.Active === false
                                              ? "line-through"
                                              : "",
                                        }}
                                      >
                                        <td className="text-nowrap text-center align-content-center">
                                          <input
                                            type="checkbox"
                                            checked={exist}
                                            onClick={(e) => {
                                              console.log(
                                                e.target.checked,
                                                item.id
                                              );
                                              handleCheckboxChange(e, item);
                                            }}
                                          />
                                        </td>
                                        <td className="align-content-center">
                                          {dateFormater(item.TransactionDate)}
                                        </td>
                                        <td className="text-center align-content-center">
                                          <span
                                            className="badge w-100"
                                            style={{
                                              background:
                                                item.BackgroundColorDescrip ||
                                                "gray",
                                              color:
                                                item.TextColorDescrip || "white",
                                            }}
                                          >
                                            {item.Description}
                                          </span>
                                        </td>
                                        <td className="align-content-center">
                                          {item.BillingService || "-"}
                                        </td>
                                        <td className="align-content-center">
                                          {item.Reference}
                                        </td>
                                        <td className="text-end align-content-center">
                                          {USDollar(item.Amount)}
                                        </td>
                                        <td className="text-end align-content-center">
                                          { (item.Description.toLowerCase() === 'charge' || item.Description.toLowerCase() === 'deposit') ? USDollar(item.PendingAmount) : null }
                                        </td>
                                        <td className="text-center align-content-center">
                                          <span
                                            className="badge w-100"
                                            style={{
                                              background:
                                                item.BackgroundColorStatus,
                                              color: item.TextColorStatus,
                                            }}
                                          >
                                            {item.Status}
                                          </span>
                                        </td>
                                        <td className="text-end align-content-center py-0">
                                          {item.Active && (
                                            <Dropdown>
                                              <Dropdown.Toggle
                                                variant="button"
                                                id="dropdown-basic"
                                                size="sm"
                                              >
                                                <i className="fa fa-ellipsis-v" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                {
                                                  item.AllowReversal 
                                                  // && moment(user.OperationDate).isSame(moment(), "day") 
                                                  ? 
                                                (
                                                  <Dropdown.Item
                                                    onClick={() => {
                                                      reverse(item);
                                                    }}
                                                  >
                                                    Reverse
                                                  </Dropdown.Item>
                                                ) : null}

                                                {item.AllowAdjustment &&
                                                  item.Status.toLowerCase() !==
                                                  "paid" && (
                                                    <Dropdown.Item
                                                      onClick={() => {
                                                        //      toggleOpen("D");
                                                        toggleAjustment(item);
                                                      }}
                                                    >
                                                      Adjust
                                                    </Dropdown.Item>
                                                  )}

                                                {item.AllowTransfer &&
                                                  item.PendingAmount ===
                                                  item.Amount ? (
                                                  <Dropdown.Item
                                                    onClick={() => {
                                                      setSelectedFolioDetails([
                                                        item,
                                                      ]);
                                                      toggleTransfer(); //(item);
                                                    }}
                                                  >
                                                    Transfer
                                                  </Dropdown.Item>
                                                ) : null}

                                                {item.TransactionTypeCode ===
                                                  "P" && (
                                                    <>
                                                      <Dropdown.Item
                                                        onClick={() => {
                                                          togglePrintInvoice(item);
                                                        }}
                                                      >
                                                        Print Invoice
                                                      </Dropdown.Item>
                                                    </>
                                                  )}
                                                {(item.StatusCode === "I" ||
                                                  item.StatusCode === "P") &&
                                                  item.Active && (
                                                    <>
                                                      {item.Status?.toLowerCase() !==
                                                        "partial payment" && (
                                                          <Dropdown.Divider />
                                                        )}
                                                      <Dropdown.Item
                                                        onClick={() => {
                                                          toggleRelatedInvoice(
                                                            item
                                                          );
                                                        }}
                                                      >
                                                        Related Invoice
                                                      </Dropdown.Item>
                                                    </>
                                                  )}
                                                {item.TransactionTypeCode ===
                                                  "P" && !item.Active ? (
                                                  <Dropdown.Item
                                                    onClick={() => {
                                                      nullInvoice(item);
                                                    }}
                                                  >
                                                    Null Invoice
                                                  </Dropdown.Item>
                                                ) : null}
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          )}
                                        </td>
                                      </tr>
                                    );
                                  })
                                : (<tr>
                                  <td colSpan="9" className="text-center bg-light">{t("No records found on folio selected")}</td>
                                </tr>)
                              }
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </Card.Body>
                    <Card.Footer>
                      <Row>
                        <Col md={12} className="text-end">
                          <span>
                            {t("Grand Total")} {generalInfo && generalInfo.CurrencyCode}:{" "}
                            {USDollar(totalAmountFolioDetails)}
                          </span>
                        </Col>
                      </Row>
                    </Card.Footer>
                  </Card>
                </Col>
              </Row>
            </Container>}
          {collectionFormatselected === 'M' && <CollectionFormatcomponent
            UpdateMasterfolioSelectedItems={(items) => setMasterfolioSelectedItems(items)}
            ref={MasterFolioRef}
            setMasterFolioState={(state) => setMasterFolioState(state)}
            masterFolioState={masterFolioState}
            reservationInfo={reservationInfo}
            currencySelected={currencySelected}
          />
          }
        </Modal.Body>
        <Modal.Footer style={{ maxHeight: "7vh" }} className="py-0">
          <Row className="w-100">
            {/* // Currency Field */}
            <Col xs={6} className="text-start d-flex gap-2">
              <div>
                {CurrencyFields.filter((x) => x.Group === "footer").map(
                  (item, index) => {
                    return (
                      <div key={index} className="d-flex">
                        <span>
                          <label className="me-2 mt-2">{item.FieldTitle}</label>
                          {item.Required && <i className="required-asterisk ms-1 text-danger">*</i>}
                          <br />
                        </span>
                        <FieldSelector
                          model={item}
                          key={index}
                          updateField={handleCurrencyChange}
                          value={currencySelected}
                        />
                      </div>
                    );
                  }
                )}
              </div>
              {/* Collection Format Field */}
              <div>
                {CollectionFormatFields.filter((x) => x.Group === "footer").map(
                  (item, index) => {
                    return (
                      <div key={index} className="d-flex">
                        <span>
                          <label className="ms-2 mt-2">{item.FieldTitle}</label>
                          {item.Required && <i className="required-asterisk ms-1 text-danger">*</i>}
                          <br />
                        </span>
                        <FieldSelector
                          model={item}
                          key={index}
                          updateField={handleCollectionFormatChange}
                          value={collectionFormatselected}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            </Col>
            <Col xs={6} className="text-end">
              {collectionFormatselected === 'R' &&
                (<div className="d-flex justify-content-end align-content-center">
                  {principalButtonList.map((item, index) => {
                    

                    if (
                      (item.folioSelected && selectedFolio === null) ||
                      (item.folioDetailSelected &&
                        selectedFolioDetails.length === 0)
                    ) {
                      return null;
                    }

                    if (
                      item?.reservationCode.length > 0 &&
                      item?.reservationCode.includes(
                        selectedItem?.ReservationCode
                      )
                    ) {
                      item.className = "border border-black";
                      return (
                        <div key={index} className="d-inline">
                          <Button
                            key={item}
                            variant={item.variant}
                            // size="lg"
                            className={item.className + " me-2"}
                            onClick={item.onClick}
                          >
                            <i className={item.icon} /> {item.title}
                          </Button>
                        </div>
                      );
                    }
                    if (item.reservationSelected && generalInfo !== null) {
                      return (
                        <div key={index} className="d-inline">
                          <Button
                            key={item}
                            variant={item.variant}
                            // size="lg"
                            className={item.className + " me-2"}
                            onClick={item.onClick}
                          >
                            <i className={item.icon} /> {item.title}
                          </Button>
                        </div>
                      );
                    }

                    return (
                      <div key={index} className="d-inline">
                        <Button
                          key={item}
                          variant={item.variant}
                          // size="lg"
                          className={item.className + " me-2"}
                          onClick={item.onClick}
                        >
                          <i className={item.icon} /> {item.title}
                        </Button>
                      </div>
                    );
                  })}
                </div>)}
              {
                collectionFormatselected === 'M' &&
                <div className="btn-group">
                  <MasterFolioPrincipalBtnGroup
                    selectedFolio={masterFolioState.selectedFolio}
                    selectedFolioDetail={masterFolioState.MasterFolioDetailSelected}
                    selectedItem={masterFolioState.selectedItem}
                    generalInfo={masterFolioState.generalInfo}
                    searchMasterFolio={() => MasterFolioRef.current?.MasterFolioSearch()}
                    MasterFolioRef={() => MasterFolioRef.current?.MasterFolioSearch()}
                    MasterfolioSelectedItems={MasterfolioSelectedItems}
                    masterFolioState={masterFolioState}
                  />
                </div>
              }
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CashierModal;
