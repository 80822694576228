export const RoomOccupationJsonFile = {
  "rooms": [
    {
      "text": "101",
      "id": "101",
      "color": "#cb6bb2",
      "roomType": "CPJS",
      "roomColorStatus": "OC Clean",
    }, {
      "text": "102",
      "id": "102",
      "color": "#56ca85",
      "roomType": "CPJS",
      "roomColorStatus": "OC Dirty",
    }, {
      "text": "103",
      "id": "103",
      "color": "#1e90ff",
      "roomType": "CPJS",
      "roomColorStatus": "OC Clean",
    }, {
      "text": "104",
      "id": "104",
      "color": "#ff9747",
      "roomType": "CPJS",
      "roomColorStatus": "OC Dirty",
    }, {
      "text": "105",
      "id": "105",
      "color": "#ff0000",
      "roomType": "CPJS",
      "roomColorStatus": "VC Clean",
    },
    {
      "text": "201",
      "id": "201",
      "color": "#00ff00",
      "roomType": "CPSPA",
      "roomColorStatus": "VC Dirty",
    }, {
      "text": "202",
      "id": "202",
      "color": "#0000ff",
      "roomType": "CPSPA",
      "roomColorStatus": "OC Clean",
    }, {
      "text": "203",
      "id": "203",
      "color": "#ffff00",
      "roomType": "CPSPA",
      "roomColorStatus": "VC Dirty",
    }, {
      "text": "204",
      "id": "204",
      "color": "#ff00ff",
      "roomType": "CPSPA",
      "roomColorStatus": "OC Clean",
    }, {
      "text": "205",
      "id": "205",
      "color": "#00ffff",
      "roomType": "CPSPA",
      "roomColorStatus": "OC Clean",
    },
    {
      "text": "301",
      "id": "301",
      "color":"#ff8000",
      "roomType": "CPSPA",
      "roomColorStatus": "OC Dirty",
    }, {
      "text": "302",
      "id": "302",
      "color": "#008000",
      "roomType": "CPSPA",
      "roomColorStatus": "VC Dirty",
    }, {
      "text": "303",
      "id": "303",
      "color":"#800080",
      "roomType": "CPSPA",
      "roomColorStatus": "VC Dirty",
    }, {
      "text": "304",
      "id": "304",
      "color":"#808080",
      "roomType": "CPSPA",
      "roomColorStatus": "OC Clean",
    }, {
      "text": "305",
      "id": "305",
      "color": "#800000",
      "roomType": "CPSPA",
      "roomColorStatus": "VC Clean",
    },
    {
      "text": "401",
      "id": "401",
      "color":"#ff8000",
      "roomType": "CPSPA",
      "roomColorStatus": "OC Dirty",
    }, {
      "text": "402",
      "id": "402",
      "color": "#008000",
      "roomType": "CPSPA",
      "roomColorStatus": "VC Dirty",
    }, {
      "text": "403",
      "id": "403",
      "color":"#800080",
      "roomType": "CPSPA",
      "roomColorStatus": "VC Dirty",
    }, {
      "text": "404",
      "id": "404",
      "color":"#808080",
      "roomType": "CPSPA",
      "roomColorStatus": "OC Clean",
    }, {
      "text": "405",
      "id": "405",
      "color": "#800000",
      "roomType": "CPSPA",
      "roomColorStatus": "VC Clean",
    },
  ],
  "dataChart": [{
    "text": "Stephenie Thurling",
    "startDate": "2024-04-20T12:58:45Z",
    "endDate": "2024-04-24T06:14:02Z",
    "room": "404",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Britni Yakutin",
    "startDate": "2024-04-04T10:31:36Z",
    "endDate": "2024-04-24T21:22:07Z",
    "room": "101",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Avrom Casperri",
    "startDate": "2024-04-03T02:32:21Z",
    "endDate": "2024-12-22T04:41:48Z",
    "room": "104",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Annadiane Gimblett",
    "startDate": "2024-04-25T13:05:50Z",
    "endDate": "2024-12-20T03:46:25Z",
    "room": "101",
    "reservationColor": "#fe8a02",
    "reservationStatus": "Tentative"

  }, {
    "text": "Gregoire Petzold",
    "startDate": "2024-04-06T04:26:27Z",
    "endDate": "2024-12-25T04:00:41Z",
    "room": "204",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Kingsley Sommerville",
    "startDate": "2024-04-14T07:00:26Z",
    "endDate": "2024-12-26T00:51:21Z",
    "room": "304",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Linn Novkovic",
    "startDate": "2024-04-01T04:36:07Z",
    "endDate": "2024-12-23T20:12:28Z",
    "room": "102",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Lothaire Halsey",
    "startDate": "2024-04-14T18:39:48Z",
    "endDate": "2024-12-23T02:39:22Z",
    "room": "202",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Inesita Pochin",
    "startDate": "2024-04-06T02:04:27Z",
    "endDate": "2024-12-21T21:58:38Z",
    "room": "205",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Granger Cathee",
    "startDate": "2024-04-07T02:01:46Z",
    "endDate": "2024-12-24T02:08:21Z",
    "room": "405",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Heidie Fagge",
    "startDate": "2024-04-09T09:05:12Z",
    "endDate": "2024-12-17T21:33:03Z",
    "room": "103",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Timothee Kingaby",
    "startDate": "2024-04-07T10:08:31Z",
    "endDate": "2024-12-26T04:31:45Z",
    "room": "301",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Joleen Cosin",
    "startDate": "2024-04-07T23:07:19Z",
    "endDate": "2024-04-16T06:47:47Z",
    "room": "204",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Jarvis MacMeanma",
    "startDate": "2024-04-04T15:07:49Z",
    "endDate": "2024-04-19T02:55:23Z",
    "room": "204",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Averil Bang",
    "startDate": "2024-04-01T00:17:02Z",
    "endDate": "2024-04-16T04:29:23Z",
    "room": "402",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Janene Danes",
    "startDate": "2024-04-26T22:51:47Z",
    "endDate": "2024-04-28T19:03:52Z",
    "room": "401",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Roldan Simanenko",
    "startDate": "2024-04-24T07:41:34Z",
    "endDate": "2024-04-27T04:12:04Z",
    "room": "404",
    "reservationColor": "#fe8a02",
    "reservationStatus": "Tentative"
  }, {
    "text": "Roxine Chmiel",
    "startDate": "2024-04-21T07:39:12Z",
    "endDate": "2024-04-24T06:32:56Z",
    "room": "403",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }, {
    "text": "Karie Killeley",
    "startDate": "2024-04-02T04:40:13Z",
    "endDate": "2024-04-17T19:38:08Z",
    "room": "301",
    "reservationColor": "#3713e1",
    "reservarionStatus": "Inhouse"
  }]
}

export const roomData = [
  {
    "text": '101',
    "id": 101,
    "color": '#cb6bb2',
    "roomType": 'CPJS',
    "roomColorStatus": 'OC Clean',
  }, {
    "text": '102',
    "id": 102,
    "color": '#56ca85',
    "roomType": 'CPJS',
    "roomColorStatus": 'OC Dirty',
  }, {
    "text": '103',
    "id": 103,
    "color": '#1e90ff',
    "roomType": 'CPJS',
    "roomColorStatus": 'OC Clean',
  }, {
    "text": '104',
    "id": 104,
    "color": '#ff9747',
    "roomType": 'CPJS',
    "roomColorStatus": 'OC Dirty',
  }, {
    "text": '105',
    "id": 105,
    "color": '#ff0000',
    "roomType": 'CPJS',
    "roomColorStatus": 'VC Clean',
  },
  {
    "text": '201',
    "id": 201,
    "color": '#00ff00',
    "roomType": 'CPSPA',
    "roomColorStatus": 'VC Dirty',
  }, {
    "text": '202',
    "id": 202,
    "color": '#0000ff',
    "roomType": 'CPSPA',
    "roomColorStatus": 'OC Clean',
  }, {
    "text": '203',
    "id": 203,
    "color": '#ffff00',
    "roomType": 'CPSPA',
    "roomColorStatus": 'VC Dirty',
  }, {
    "text": '204',
    "id": 204,
    "color": '#ff00ff',
    "roomType": 'CPSPA',
    "roomColorStatus": 'OC Clean',
  }, {
    "text": '205',
    "id": 205,
    "color": '#00ffff',
    "roomType": 'CPSPA',
    "roomColorStatus": 'OC Clean',
  },
  {
    "text": '301',
    "id": 301,
    "color":'#ff8000',
    "roomType": 'CPSPA',
    "roomColorStatus": 'OC Dirty',
  }, {
    "text": '302',
    "id": 302,
    "color": '#008000',
    "roomType": 'CPSPA',
    "roomColorStatus": 'VC Dirty',
  }, {
    "text": '303',
    "id": 303,
    "color":'#800080',
    "roomType": 'CPSPA',
    "roomColorStatus": 'VC Dirty',
  }, {
    "text": '304',
    "id": 304,
    "color":'#808080',
    "roomType": 'CPSPA',
    "roomColorStatus": 'OC Clean',
  }, {
    "text": '305',
    "id": 305,
    "color": '#800000',
    "roomType": 'CPSPA',
    "roomColorStatus": 'VC Clean',
  },
  {
    "text": '401',
    "id": 401,
    "color":'#ff8000',
    "roomType": 'CPSPA',
    "roomColorStatus": 'OC Dirty',
  }, {
    "text": '402',
    "id": 402,
    "color": '#008000',
    "roomType": 'CPSPA',
    "roomColorStatus": 'VC Dirty',
  }, {
    "text": '403',
    "id": 403,
    "color":'#800080',
    "roomType": 'CPSPA',
    "roomColorStatus": 'VC Dirty',
  }, {
    "text": '404',
    "id": 404,
    "color":'#808080',
    "roomType": 'CPSPA',
    "roomColorStatus": 'OC Clean',
  }, {
    "text": '405',
    "id": 405,
    "color": '#800000',
    "roomType": 'CPSPA',
    "roomColorStatus": 'VC Clean',
  },
];

export const dataChart = [{
  "text": "Stephenie Thurling",
  "startDate": "2024-04-20T12:58:45Z",
  "endDate": "2024-04-24T06:14:02Z",
  "room": 404,
  "reservationColorStatus": 'Inhouse'
}, {
  "text": "Britni Yakutin",
  "startDate": "2024-04-04T10:31:36Z",
  "endDate": "2024-04-24T21:22:07Z",
  "room": 101,
  "reservationColorStatus": 'Inhouse'
}, {
  "text": "Avrom Casperri",
  "startDate": "2024-04-03T02:32:21Z",
  "endDate": "2024-12-22T04:41:48Z",
  "room": 104,
  "reservationColorStatus": 'Inhouse'
}, {
  "text": "Annadiane Gimblett",
  "startDate": "2024-04-25T13:05:50Z",
  "endDate": "2024-12-20T03:46:25Z",
  "room": 101,
  "reservationColorStatus": 'Tentative'
}, {
  "text": "Gregoire Petzold",
  "startDate": "2024-04-06T04:26:27Z",
  "endDate": "2024-12-25T04:00:41Z",
  "room": 204,
  "reservationColorStatus": 'Inhouse'
}, {
  "text": "Kingsley Sommerville",
  "startDate": "2024-04-14T07:00:26Z",
  "endDate": "2024-12-26T00:51:21Z",
  "room": 304,
  "reservationColorStatus": 'Inhouse'
}, {
  "text": "Linn Novkovic",
  "startDate": "2024-04-01T04:36:07Z",
  "endDate": "2024-12-23T20:12:28Z",
  "room": 102,
  "reservationColorStatus": 'Inhouse'
}, {
  "text": "Lothaire Halsey",
  "startDate": "2024-04-14T18:39:48Z",
  "endDate": "2024-12-23T02:39:22Z",
  "room": 202,
  "reservationColorStatus": 'Inhouse'
}, {
  "text": "Inesita Pochin",
  "startDate": "2024-04-06T02:04:27Z",
  "endDate": "2024-12-21T21:58:38Z",
  "room": 205,
  "reservationColorStatus": 'Inhouse'
}, {
  "text": "Granger Cathee",
  "startDate": "2024-04-07T02:01:46Z",
  "endDate": "2024-12-24T02:08:21Z",
  "room": 405,
  "reservationColorStatus": 'Inhouse'
}, {
  "text": "Heidie Fagge",
  "startDate": "2024-04-09T09:05:12Z",
  "endDate": "2024-12-17T21:33:03Z",
  "room": 103,
  "reservationColorStatus": 'Inhouse'
}, {
  "text": "Timothee Kingaby",
  "startDate": "2024-04-07T10:08:31Z",
  "endDate": "2024-12-26T04:31:45Z",
  "room": 301,
  "reservationColorStatus": 'Inhouse'
}, {
  "text": "Joleen Cosin",
  "startDate": "2024-04-07T23:07:19Z",
  "endDate": "2024-04-16T06:47:47Z",
  "room": 204,
  "reservationColorStatus": 'Inhouse'
}, {
  "text": "Jarvis MacMeanma",
  "startDate": "2024-04-04T15:07:49Z",
  "endDate": "2024-04-19T02:55:23Z",
  "room": 204,
  "reservationColorStatus": 'Inhouse'
}, {
  "text": "Averil Bang",
  "startDate": "2024-04-01T00:17:02Z",
  "endDate": "2024-04-16T04:29:23Z",
  "room": 402,
  "reservationColorStatus": 'Inhouse'
}, {
  "text": "Janene Danes",
  "startDate": "2024-04-26T22:51:47Z",
  "endDate": "2024-04-28T19:03:52Z",
  "room": 401,
  "reservationColorStatus": 'Inhouse'
}, {
  "text": "Roldan Simanenko",
  "startDate": "2024-04-24T07:41:34Z",
  "endDate": "2024-04-27T04:12:04Z",
  "room": 404,
  "reservationColorStatus": 'Tentative'
}, {
  "text": "Roxine Chmiel",
  "startDate": "2024-04-21T07:39:12Z",
  "endDate": "2024-04-24T06:32:56Z",
  "room": 403,
  "reservationColorStatus": 'Inhouse'
}, {
  "text": "Karie Killeley",
  "startDate": "2024-04-02T04:40:13Z",
  "endDate": "2024-04-17T19:38:08Z",
  "room": 301,
  "reservationColorStatus": 'Inhouse'
}]

export const dbObj = {
  rooms: [
    {
      text: '1136',
      id: '1136',
      color: '#000000',
      backgroundColor: '#FF7F7F',
      roomType: 'APT',
      roomColorStatus: 'VD Dirty'
    },
    {
      text: '1151',
      id: '1151',
      color: '#ffffff',
      backgroundColor: '#228B22',
      roomType: 'PSL',
      roomColorStatus: 'OC Clean'
    },
    {
      text: '1232',
      id: '1232',
      color: '#ffffff',
      backgroundColor: '#228B22',
      roomType: 'APT2',
      roomColorStatus: 'OC Clean'
    },
    {
      text: '1456',
      id: '1456',
      color: '#ffffff',
      backgroundColor: '#228B22',
      roomType: 'APT',
      roomColorStatus: 'OC Clean'
    },
    {
      text: '1543',
      id: '1543',
      color: '#ffffff',
      backgroundColor: '#228B22',
      roomType: 'APT',
      roomColorStatus: 'OC Clean'
    },
    {
      text: '1555',
      id: '1555',
      color: '#000000',
      backgroundColor: '#FF7F7F',
      roomType: 'APT2',
      roomColorStatus: 'VD Dirty'
    },
    {
      text: '1575',
      id: '1575',
      color: '#ffffff',
      backgroundColor: '#228B22',
      roomType: 'PSD',
      roomColorStatus: 'OC Clean'
    },
    {
      text: '1652',
      id: '1652',
      color: '#000000',
      backgroundColor: '#FF7F7F',
      roomType: 'CPPH',
      roomColorStatus: 'VD Dirty'
    },
    {
      text: '1672',
      id: '1672',
      color: '#ffffff',
      backgroundColor: '#228B22',
      roomType: 'PSD',
      roomColorStatus: 'OC Clean'
    },
    {
      text: '1676',
      id: '1676',
      color: '#ffffff',
      backgroundColor: '#228B22',
      roomType: 'APT2',
      roomColorStatus: 'OC Clean'
    }
  ],
  dataChart: [
    {
      text: 'MARILENNY SORIANO',
      suitedId: 44,
      startDate: new Date('2024-04-14T04:00:00Z'),
      endDate: new Date('2024-05-14T04:00:00Z'),
      room: '1136',
      reservationTextColor: '#006600',
      reservationColor: '#ccffcc',
      reservationStatus: 'Confirmed'
    },
    {
      text: 'Josias Borge',
      suitedId: 44,
      startDate: new Date('2024-04-20T04:00:00Z'),
      endDate: new Date('2024-04-25T04:00:00Z'),
      room: '1136',
      reservationTextColor: '#666600',
      reservationColor: '#ffffcc',
      reservationStatus: 'Partial Check-out'
    },
    {
      text: 'Ramón Pérez',
      suitedId: 1,
      startDate: new Date('2024-04-20T04:00:00Z'),
      endDate: new Date('2024-04-21T04:00:00Z'),
      room: '1151',
      reservationTextColor: '#4d4d4d',
      reservationColor: '#d9d9d9',
      reservationStatus: 'Check-out'
    },
    {
      text: 'Delivery Agency Group No.: 45',
      suitedId: 1,
      startDate: new Date('2024-05-14T04:00:00Z'),
      endDate: new Date('2024-05-29T04:00:00Z'),
      room: '1151',
      reservationTextColor: '#006600',
      reservationColor: '#ccffcc',
      reservationStatus: 'Confirmed'
    },
    {
      text: 'ytry tytry',
      suitedId: 54,
      startDate: new Date('2024-04-20T04:00:00Z'),
      endDate: new Date('2024-04-21T04:00:00Z'),
      room: '1232',
      reservationTextColor: '#4d4d4d',
      reservationColor: '#d9d9d9',
      reservationStatus: 'Check-out'
    },
    {
      text: 'Kenia Hernández',
      suitedId: 54,
      startDate: new Date('2024-06-17T04:00:00Z'),
      endDate: new Date('2024-06-20T04:00:00Z'),
      room: '1232',
      reservationTextColor: '#ffffff',
      reservationColor: '#002266',
      reservationStatus: 'In house'
    },
    {
      text: 'Genoveva González',
      suitedId: 50,
      startDate: new Date('2024-03-20T04:00:00Z'),
      endDate: new Date('2024-04-06T04:00:00Z'),
      room: '1456',
      reservationTextColor: '#003366',
      reservationColor: '#99ccff',
      reservationStatus: 'Partial Check-in'
    },
    {
      text: 'MARIBEL SOLÉ',
      suitedId: 50,
      startDate: new Date('2024-04-14T04:00:00Z'),
      endDate: new Date('2024-05-14T04:00:00Z'),
      room: '1456',
      reservationTextColor: '#003366',
      reservationColor: '#99ccff',
      reservationStatus: 'Partial Check-in'
    },
    {
      text: 'TESTING GROUP RESIDENTIAL Group No.: 63',
      suitedId: 45,
      startDate: new Date('2024-06-18T04:00:00Z'),
      endDate: new Date('2024-06-23T04:00:00Z'),
      room: '1543',
      reservationTextColor: '#ffffff',
      reservationColor: '#002266',
      reservationStatus: 'In house'
    },
    {
      text: 'Osvaldo Reyes',
      suitedId: 46,
      startDate: new Date('2024-03-12T04:00:00Z'),
      endDate: new Date('2024-03-18T04:00:00Z'),
      room: '1555',
      reservationTextColor: '#006600',
      reservationColor: '#ccffcc',
      reservationStatus: 'Confirmed'
    },
    {
      text: 'Pablo Domínguez',
      suitedId: 46,
      startDate: new Date('2024-04-01T04:00:00Z'),
      endDate: new Date('2024-04-05T04:00:00Z'),
      room: '1555',
      reservationTextColor: '#006600',
      reservationColor: '#ccffcc',
      reservationStatus: 'Confirmed'
    },
    {
      text: 'Alisson Blue',
      suitedId: 46,
      startDate: new Date('2024-06-18T04:00:00Z'),
      endDate: new Date('2024-06-20T04:00:00Z'),
      room: '1555',
      reservationTextColor: '#003366',
      reservationColor: '#cceeff',
      reservationStatus: 'Tentative'
    },
    {
      text: 'prueba 5 cambio de hotel',
      suitedId: 8,
      startDate: new Date('2024-04-01T04:00:00Z'),
      endDate: new Date('2024-04-06T04:00:00Z'),
      room: '1575',
      reservationTextColor: '#ffffff',
      reservationColor: '#002266',
      reservationStatus: 'In house'
    },
    {
      text: 'Signos Group No.: 46',
      suitedId: 8,
      startDate: new Date('2024-04-07T04:00:00Z'),
      endDate: new Date('2024-04-11T04:00:00Z'),
      room: '1575',
      reservationTextColor: '#003366',
      reservationColor: '#cceeff',
      reservationStatus: 'Tentative'
    },
    {
      text: ' ',
      suitedId: 8,
      startDate: new Date('2024-04-20T04:00:00Z'),
      endDate: new Date('2024-04-21T04:00:00Z'),
      room: '1575',
      reservationTextColor: '#003366',
      reservationColor: '#cceeff',
      reservationStatus: 'Tentative'
    },
    {
      text: 'prueba reservation 3 Group: 100000000182',
      suitedId: 8,
      startDate: new Date('2024-06-07T04:00:00Z'),
      endDate: new Date('2024-06-30T04:00:00Z'),
      room: '1575',
      reservationTextColor: '#006600',
      reservationColor: '#ccffcc',
      reservationStatus: 'Confirmed'
    },
    {
      text: 'Roislan Brizuela',
      suitedId: 61,
      startDate: new Date('2024-03-11T04:00:00Z'),
      endDate: new Date('2024-03-21T04:00:00Z'),
      room: '1652',
      reservationTextColor: '#003366',
      reservationColor: '#cceeff',
      reservationStatus: 'Tentative'
    },
    {
      text: 'Helen García',
      suitedId: 61,
      startDate: new Date('2024-07-03T04:00:00Z'),
      endDate: new Date('2024-07-06T04:00:00Z'),
      room: '1652',
      reservationTextColor: '#000000',
      reservationColor: '#f4b084',
      reservationStatus: 'No show'
    },
    {
      text: 'cambio prueba de hotel',
      suitedId: 59,
      startDate: new Date('2024-04-02T04:00:00Z'),
      endDate: new Date('2024-04-07T04:00:00Z'),
      room: '1672',
      reservationTextColor: '#4d4d4d',
      reservationColor: '#d9d9d9',
      reservationStatus: 'Check-out'
    },
    {
      text: 'hjkhkh hjgjghj',
      suitedId: 59,
      startDate: new Date('2024-04-20T04:00:00Z'),
      endDate: new Date('2024-04-21T04:00:00Z'),
      room: '1672',
      reservationTextColor: '#4d4d4d',
      reservationColor: '#d9d9d9',
      reservationStatus: 'Check-out'
    },
    {
      text: 'Alfredo Soler',
      suitedId: 59,
      startDate: new Date('2024-06-14T04:00:00Z'),
      endDate: new Date('2024-06-21T04:00:00Z'),
      room: '1672',
      reservationTextColor: '#003366',
      reservationColor: '#cceeff',
      reservationStatus: 'Tentative'
    },
    {
      text: 'Juan Martínez',
      suitedId: 51,
      startDate: new Date('2024-02-01T04:00:00Z'),
      endDate: new Date('2024-02-03T04:00:00Z'),
      room: '1676',
      reservationTextColor: '#003366',
      reservationColor: '#cceeff',
      reservationStatus: 'Tentative'
    },
    {
      text: 'Margarita Núñez',
      suitedId: 51,
      startDate: new Date('2024-06-11T04:00:00Z'),
      endDate: new Date('2024-06-14T04:00:00Z'),
      room: '1676',
      reservationTextColor: '#003366',
      reservationColor: '#99ccff',
      reservationStatus: 'Partial Check-in'
    },
    {
      text: 'TESTING GROUP RESIDENTIAL Group No.: 63',
      suitedId: 51,
      startDate: new Date('2024-06-18T04:00:00Z'),
      endDate: new Date('2024-06-23T04:00:00Z'),
      room: '1676',
      reservationTextColor: '#003366',
      reservationColor: '#99ccff',
      reservationStatus: 'Partial Check-in'
    },
    {
      text: 'JUNIO NÚÑEZ',
      suitedId: 51,
      startDate: new Date('2024-06-22T04:00:00Z'),
      endDate: new Date('2024-07-22T04:00:00Z'),
      room: '1676',
      reservationTextColor: '#006600',
      reservationColor: '#ccffcc',
      reservationStatus: 'Confirmed'
    }
  ]  
}