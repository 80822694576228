import Scheduler, { Resource, Scrolling } from "devextreme-react/scheduler";
import React, { useCallback, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import "./custom-scheduler-style.css";
import {
  // dataChart,
  // roomData,
  // RoomOccupationJsonFile,
  dbObj,
} from "./exampleData";
import moment from "moment";
import { useTranslation } from "react-i18next";
import API from "../../api/api";
import { useToast } from "../../contexts/toast";
import { parsedUser } from "../../utils/GetCurrentUser";
// import CustomStore from "devextreme/data/custom_store";
// import { RoomOccupationJsonFile } from "./exampleData";

const views = [
  { name: "Months", type: "timelineMonth", intervalCount: 2 },
  {
    name: "Weeks",
    type: "timelineWeek",
    intervalCount: 4,
  },
];

const RoomOccupation = () => {
  const { t } = useTranslation();
  const user = parsedUser();
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  // const [records, setRecords] = useState(dbObj.dataChart);
  // const [records, setRecords] = useState(dataChart);
  const [records, setRecords] = useState([]);
  const [roomsData, setRoomsData] = useState([]);
  // const [roomsData, setRoomsData] = useState(roomData);
  // const [roomsData, setRoomsData] = useState(dbObj.rooms);
  const [search, setSearch] = useState("");
  // const [currentView, setCurrentView] = useState(views[0]);

  // const myCustomStore = new CustomStore({
  //   key: 'id',
  //   async load(loadOptions) {
  //       console.log(loadOptions)
  //       const startDate = loadOptions.startDate;
  //       const endDate = loadOptions.endDate;
  //       try {
  //         bindDataRemote({startDate: startDate, endDate: endDate});
  //           // const response = await fetch(`https://my-server.com/api/appointments?startDate=${startDate}&endDate=${endDate}`);
  //           // const result = await response.json();
  //           //     return {
  //           //       data: result.data
  //           //     }
  //       } catch (err) {
  //           throw new Error('Data Loading Error');
  //       }
  //   }
  // });

  const groups = ["room"];

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    // if (roomsData === null) return;
    // if (search === "") {
    //   await bindDataRemote();
    //   return;
    // }
    // setRoomsData(
    //   roomsData &&roomsData.filter((val) => {
    //     return val?.text?.toLowerCase().includes(search?.toLowerCase());
    //   })
    // );
   
  };

  useEffect(() => {
    bindDataRemote({startDate: moment().format('MM-DD-YYYY'), endDate: moment().add(4, 'weeks').format('MM-DD-YYYY')});
  }, [])

  const convertDateToUTC = (date) => {
    return new Date(date);
    // return moment(date).toISOString();
  }

  const handleDateChange = async (date) => {
    const currentDate = moment(date).format("MM-DD-YYYY");
    const endDate = moment(date).add(4, "weeks").format("MM-DD-YYYY");
    await bindDataRemote({startDate: currentDate, endDate: endDate});
    // console.log({startDate: currentDate, endDate: endDate});
  }

  const bindDataRemote = async (filter) => {
    setIsLoading(true)
    console.log("🚀 ~ bindDataRemote ~ filter:", filter)

    // startDate = moment("20230101", "YYYYMMDD").format("MM-DD-YYYY"),
    // endDate = moment("20250101", "YYYYMMDD").format("MM-DD-YYYY"),

    const {
      startDate,
      endDate,
    } = filter;



    try {
      let queryString = `IdUser=${user.IdUser}&token=${user.Token}`;
      let queryData = {
        Data: `@IdBusinessUnit=${user.IdBusinessUnit}, @StartDate='${startDate}', @EndDate='${endDate}'`,
      };
      let request = await API.postAction(
        "/api/Rooms/RoomOccupation?" + queryString,
        queryData
      );
      let results = JSON.parse(request.data[0].JSONData);
      console.log("🚀 ~ bindDataRemote ~ results:", results)
      if (results === null) {
        setRecords([]);
        setRoomsData([]);
        return;
      }
      let recordResult = results;
      const parsedRoomData = recordResult?.rooms.map((item) => {
        return {
          ...item,
          id: item.text
        };
      });
      setRoomsData(parsedRoomData);
      // setRoomsData(dbObj.rooms);
      // setRoomsData(recordResult?.rooms);
      const parsedDatachart = recordResult?.dataChart.map((item) => {
        return {
          ...item,
          startDate: convertDateToUTC(item.startDate),
          endDate: convertDateToUTC(item.endDate)
        };
      });
      setRecords(parsedDatachart);
      // setRecords(dbObj.dataChart);
      // setRecords(datachart);
      console.log("🚀 ~ bindDataRemote ~ recordResult.parsedDatachart:", parsedDatachart)
      console.log("🚀 ~ bindDataRemote ~ recordResult.parsedRoomData:", parsedRoomData)
      if (recordResult === null) {
        toast({
          title: "No data found",
          status: "error",
        });
        throw new Error("No data found");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  const appointmentTemplate = (model) => {
    console.log("🚀 ~ appointmentTemplate ~ model:", model)
    const { appointmentData } = model;
    return (
      <div
        className=""
        style={{
          backgroundColor: appointmentData.reservationColor,
          color: appointmentData.reservationTextColor,
        }}
      >
        <div className="">{appointmentData.text}</div>
        <div className="">{appointmentData.room} </div>
      </div>
    );
  };

  const renderGroupCell = (props) => {
    return (
      <div className="d-flex gap-0 h-100 align-items-center" style={{backgroundColor: props.data.backgroundColor, width: '125px'}}>
        <div
          className="d-flex aling-items-center justify-content-center text-center w-100 gap-2"
          style={{
            backgroundColor: props.data.backgroundColor,
            color: props.data.color,
          }}
        >
          <span className="fw-bold text-center">
            {props.data.text}
          </span>
          <span>
            {props.data.roomType}
          </span>
        </div>
        {/* <div className="mx-2"
          style={{
            backgroundColor: props.data.backgroundColor,
            color: props.data.color,
            fontWeight: "bold",
            width: '100%'
          }}
        >{props.data.roomType}</div> */}
      </div>
    );
  };

  const renderDateCell = useCallback((itemData) => {
    const isWeekend =
      itemData.date.getDay() === 0 || itemData.date.getDay() === 6;
    const classCustom = isWeekend
      ? "pt-3 w-100 fw-bolder text-dark"
      : "text-dark";
    const styleCustom = isWeekend ? { backgroundColor: "#FFB0B0" } : {};
    const date = itemData.date;

    // Obtener el nombre del día (por ejemplo, 'Lunes')
    const dayName = new Intl.DateTimeFormat("en", {
      weekday: "short",
    }).format(date);

    // Obtener el número del día del mes
    const dayNumber = date.getDate();

    /* <div className={backgroundColor}>
        <div>{itemData.text}</div>
      </div> */

    return (
      <>
        <div className={classCustom} style={styleCustom}>
          <span class="dx-scheduler-header-panel-cell-date ">{dayName}</span>
          <span class="dx-scheduler-header-panel-cell-date fs-3">
            {dayNumber}
          </span>
        </div>
      </>
    );
  }, []);

  return (
    <>
      <Card bg="transparent" border="0">
        <Card.Body className="ps-0 pt-0 pb-3">
          <Row>
            <Col xs={12} sm={6} xl={9}>
              <form onSubmit={handleSubmit}>
                <div className="w-75 d-flex gap-2 align-items-end">
                  <input
                    type="text"
                    className="form-control"
                    placeholder={t("Filter")}
                    onChange={handleChange}
                  />
                  <button type="submit" className="btn btn-primary mt-2" disabled={isLoading}>
                    {t("Search")}
                  </button>
                </div>
              </form>
            </Col>
            <Col xs={12} sm={6} xl={3} className="text-end">
              <button className="btn btn-lg">
                <i class="fa-solid fa-circle-question"></i>
              </button>

              <button className="btn btn-lg">
                <i class="fa-solid fa-magnifying-glass"></i>
              </button>

              <button className="btn btn-lg">
                <i class="fa-solid fa-cash-register"></i>
              </button>

              <button className="btn btn-lg">
                <i class="fa-solid fa-people-group"></i>
              </button>

              <button className="btn btn-lg border-end">
                <i class="fa-solid fa-folder-tree"></i>
              </button>

              <button className="btn btn-lg btn-link" type="button">
                <i class="fa-solid fa-rotate"></i>
              </button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Scheduler
        id={'scheduler'}
        timeZone="America/Los_Angeles"
        // dataSource={myCustomStore}
        dataSource={records || []}
        // dataSource={Array.isArray(records) ? records : []}
        views={views}
        // currentDate={new Date()}
        defaultCurrentView={"Weeks"}
        height={'auto'}
        groups={groups}
        cellDuration={1440}
        firstDayOfWeek={0}
        groupByDate={false}
        timeCellComponent={null}
        resourceCellRender={renderGroupCell}
        showAllDayPanel={false}
        appointmentRender={appointmentTemplate}
        showCurrentTimeIndicator={false}
        maxAppointmentsPerCell={1}
        shadeUntilCurrentTime={false}
        dateCellRender={renderDateCell}
        remoteFiltering={true}
        disabled={isLoading}
        onOptionChanged={(e) => {
          if (e.name === "currentDate") {
            // if (isLoading) return;
            console.log(e);
            handleDateChange(e.value);
            // setCurrentView(e.value);
          }
        }
        }
      >
        <Resource
          fieldExpr="room"
          allowMultiple={true}
          dataSource={roomsData ? roomsData : []}
          label={t("Room")}
          useColorAsDefault={false}
        />

        <Scrolling mode="virtual" />
      </Scheduler>
    </>
  );
};

export default RoomOccupation;
