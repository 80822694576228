import { HighlightOff, Search, Visibility } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Accordion, Card, Col, Form, Row } from "react-bootstrap";
import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import { useToast } from "../../contexts/toast";
import { useTranslation } from "react-i18next";

const RoomMapFilter = (props) => {
  const user = parsedUser();
  const toast = useToast();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [records, setRecords] = useState([]);
  const [businessUnitSelected, setBusinessUnitSelected] = useState(null);

  useEffect(() => {
    requestOrganizations();
  }, [])

  const requestOrganizations = async () => {
    setIsLoading(true);
    try {
      setIsLoading(true);

      let queryString = `IdUser=${user.IdUser}&Token=${user.Token}`;
      let requestAPI = await API.postAction(
        `api/account/ChangeBusinessUnit?${queryString}`,
        { Data: null }
      );
      if (requestAPI.data) {
        // console.log(requestAPI.data);
        let data = requestAPI.data[0].JSONData;
        // console.log(data);
        let parsed = JSON.parse(data);
        // console.log(parsed);
        setRecords(parsed[0].Records);
      }
    } catch (error) {
      console.error(error);
      toast({
        type: "error",
        message: error.message || "An error occurred",
        width: 'auto',
        height: 'auto'
      })
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (formikObj) => {
    if (businessUnitSelected === null) {
      return;
    }
    // const obj = formikObj;
    // let objSPVersion = "";
    props.handleFilter({IdBusinessUnit: businessUnitSelected});
  }

  const HandleBusinessUnit = (fieldValue) => {
    setBusinessUnitSelected(fieldValue);
  }

  return (
    <Accordion className="mb-4 mt-0 p-0" defaultActiveKey={"0"}>
      <Accordion.Item eventKey="0">
        <Accordion.Header className="my-0"><span className='h5 m-0' style={{ fontSize: '14px' }}>Filter Room Map</span></Accordion.Header>
        <Accordion.Body className="bg-light p-0">
          <Card className="w-100">
            <Form>
              <Card.Body className="bg-light">
                <Row className='mx-0'>
                  <Col xs={6} className="my-4">
                    <div className='mx-4'>
                      <Form.Group className="d-flex">
                        <Form.Label className="mt-2 d-block" style={{ width: '150px' }}>Business Unit</Form.Label>
                        <Form.Select onChange={(e) => HandleBusinessUnit(e.target.value)} disabled={isLoading}>
                          <option value="null">Select...</option>
                          {records?.length > 0 && records?.map((item, index) => {
                            return <option key={index} value={item.IdBusinessUnit}>{item.BusinessUnit}</option>
                          }
                          )}
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer >
                <Row>
                  <Col>
                    {/* <div className='display-6 text-success'>
                Reservations found: {props.count || 0}
              </div> */}
                  </Col>
                  <Col xs='auto text-end'>
                    <Col className="col-12 d-flex justify-content-end gap-2 mt-3">
                    </Col>
                    <button
                      className="btn btn-sm btn-outline-secondary me-2"
                      title={t("Clear")}
                      type="button"
                      onClick={props.clearReport}
                      disabled={isLoading}
                    >
                      <HighlightOff /> {t("Clear")}
                    </button>
                    <button
                      className="btn btn-sm btn-primary"
                      title={t("Search")}
                      type="button"
                      onClick={() => handleSubmit(businessUnitSelected)}
                      disabled={isLoading}
                    >
                      <Visibility /> {t("Preview")}
                    </button>
                  </Col>
                </Row>
              </Card.Footer>
            </Form>
          </Card>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
};

export default RoomMapFilter;
