import React, { Fragment, useState } from "react";
import { CustomBreadcrumbs as Breadcrumbs } from "../../components";
import { Button, Container, ButtonGroup } from "react-bootstrap";
import ReservationContainer from "../../components/reservations/reservationsContainer";
import ReservationDetails from "../../components/reservations/reservationDetails/ReservationDetails";
import CancelReservation from "../../components/reservations/CancelReservation";
import RegistrationCardModal from "../../components/reservations/RegistrationCardModal";
import ReservationFormModal from "../../components/reservations/ReservationFormModal";
import UnCancelReservation from "../../components/reservations/UnCancelReservation";
import { useToast } from "../../contexts/toast";
import ReservationAvailability from "../../components/reservations/ReservationAvailability";
import { ModalColorPicker } from "../../components/color-picker/ModalColorPicker";
import { useNavigate } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import API from "../../api/api";
import { parsedUser } from "../../utils/GetCurrentUser";
import AdvancedRoomSelection from "../../components/reservations/reservationDetails/AdvancedRoomSelection";
import CashierModal from "../../components/reservations/cashier/CashierModal";
import UndoCheckIn from "../../components/group-reservation/UndoCheckIn";
import UndoCheckout from "../../components/group-reservation/UndoCheckout";
import { useTranslation } from "react-i18next";
import BumpOutManager from "../../components/reservations/Bumpout/BumpOutManager";
import ShareRoom from "../../components/reservations/ShareRoom/ShareRoom";
import BumpOutAddEdit from "../../components/reservations/Bumpout/BumpOutAddEdit";
import moment from "moment";

const ReservationPage = (props) => {
  const user = parsedUser();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showReservationForm, setShowReservationForm] = useState(false);
  const [showRegistrationCard, setShowRegistrationCard] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [actionMode, setActionMode] = useState(null);
  const [showCancelReservation, setShowCancelReservation] = useState(false);
  const [showUnCancelReservation, setShowUnCancelReservation] = useState(false);
  const [showColorSchema, setshowColorSchema] = useState(false);

  const [showUndoCheckIn, setShowUndoCheckIn] = useState(false);

  const bindDataDetailsRemoteForCheckoutValidation = async (Reservation) => {
    try {
      let query = `IdUser=${user.IdUser}&token=${user.Token}&RecordId=${Reservation.GUID}`;
      let request = await API.getAction(
        "api/Reservations/ReservationDetails",
        query
      );

      let results = request.data[0];
      let parsedResults = JSON.parse(results.JSONData)[0].JSONData;
      let recordResult = parsedResults;
      if (recordResult === null) {
        throw new Error("No data found");
      }
      return recordResult;
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const toast = useToast();

  const toggleCancelReservation = () => {
    setShowCancelReservation(!showCancelReservation);
  };

  const toggleUnCancelReservation = () => {
    setShowUnCancelReservation(!showUnCancelReservation);
  };
  const toggleAddEdit = async (action) => {
    if (action === "VIEW") {
      setActionMode(action);
      setShow(!show);
      return;
    }
    if (action === "I") {
      setSelectedItem(null);
      setActionMode(action);
      setShow(!show);
    } else {
      if (selectedItem) {
        //*TODO - Check if the user is trying to check-in or check-out on the wrong day - UNCOMMENT when necesary.
        // const { ArrivalDate, DepartureDate } = await bindDataDetailsRemoteForCheckoutValidation(selectedItem);
        // console.log("🚀 ~ toggleAddEdit ~ data:", { ArrivalDate, DepartureDate })
        // if (action === "K" && moment(ArrivalDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
        //   toast({
        //     type: "error",
        //     message: "You can only check-in on the day of arrival.",
        //     width: 350,
        //   });
        //   return;
        // }
        // if (action === "O" && moment(DepartureDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
        //   toast({
        //     type: "error",
        //     message: "You can only check-out on the day of departure.",
        //     width: 350,
        //   });
        //   return;
        // }
      }
      setActionMode(action);
      setShow(!show);
    }
  };

  const handleCloseReservationDetails = () => {
    setShow(false);
    setSelectedItem(null);
  };

  const toggleShowRegistrationCard = () => {
    if (!selectedItem) {
      toast({
        type: "error",
        message: t("Please select a reservation"),
      });
      return;
    }
    setShowRegistrationCard(!showRegistrationCard);
  };
  const toggleShowReservationForm = () => {
    if (!selectedItem) {
      toast({
        type: "error",
        message: t("Please select a reservation"),
      });
      return;
    }
    setShowReservationForm(!showReservationForm);
  };
  const toggleColorScheme = () => {
    setshowColorSchema(!showColorSchema);
  };
  const navigate = useNavigate();
  const [showReservationAvailability, setShowReservationAvailability] =
    useState(false);
  const secondeButtonList = [
    {
      id: 1,
      name: t("Color Scheme"),
      reservationCode: [],
      onClick: () => toggleColorScheme(),
    },

    {
      id: 1,
      name: t("Bump Out"),
      reservationCode: ["T", "C", "I"],
      onClick: () => toggleBumpOutManagement(),
      requireSelection: true,
    },

    // {
    //   id: 1,
    //   name: t("Share Room"),
    //   reservationCode: [],
    //   onClick: () => toggleShareRoom(),
    // },

    {
      id: 2,
      name: t("Res. Form"),
      onClick: () => toggleShowReservationForm(),
      reservationCode: ["T", "C", "I"],
      requireSelection: true,
    },
    {
      id: 3,
      name: t("Reg. Card"),
      onClick: () => toggleShowRegistrationCard(),
      reservationCode: ["T", "C", "I"],
      requireSelection: true,
    },
    {
      id: 4,
      name: t("Print Folios"),
      onClick: () => { },
      reservationCode: [],
      requireSelection: true,
    },
    {
      id: 5,
      name: t("Check Out"),
      onClick: () => toggleAddEdit("O"),
      reservationCode: ["I", "H"],
      requireSelection: true,
    },
    {
      id: 6,
      name: t("Groups"),
      onClick: () => {
        navigate("/process/group-reservations");
      },
      reservationCode: [],
    },
    {
      id: "releaseRoom",
      name: t("Release Room"),
      onClick: async () => {
        confirmAlert({
          message: t("Are you sure you want to release the room?"),
          buttons: [
            {
              label: t("Yes"),
              onClick: async () => {
                let query = `IdUser=${user.IdUser}&token=${user.Token}&GUIDReservation=${selectedItem.GUID}`;

                let prefix = "Reservations";
                await API.getAction(`api/${prefix}/ReleaseRoom`, query);
                // await bindre();
                setReloadData(true);
              },
            },
            { label: "No" },
          ],
        });
      },
      reservationCode: ["C"],
      requireSelection: true,
    },

    {
      id: 8,
      name: t("Availability"),
      onClick: () => {
        setShowReservationAvailability(!showReservationAvailability);
      },
      reservationCode: [],
    },
  ];
  const [reloadData, setReloadData] = useState(false);
  //if the reservationCode array is empty and ifSelectedItem is false. it will always be visible.
  const principalButtonList = [
    {
      id: 1,
      title: t("Edit"),
      variant: "outline-secondary",
      className: "border border-black",
      icon: "fa-solid fa-file-pen",
      ifSelectedItem: true,
      reservationCode: ["T", "C", "I", "B", "P"],
      onClick: () => toggleAddEdit("U"),
    },
    {
      id: 2,
      title: t("New"),
      variant: "outline-primary",
      className: "",
      icon: "fa-solid fa-plus",
      ifSelectedItem: false,
      reservationCode: [],
      onClick: () => toggleAddEdit("I"),
    },
    {
      id: 3,
      title: t("Cancel"),
      variant: "outline-danger",
      className: "border border-black",
      icon: "fa-solid fa-times",
      ifSelectedItem: true,
      reservationCode: ["T", "C"],
      onClick: () => toggleCancelReservation(),
    },
    {
      id: 4,
      title: t("Uncancel"),
      variant: "outline-danger",
      className: "",
      icon: "fa-solid fa-rotate-left",
      ifSelectedItem: true,
      reservationCode: ["X"],
      onClick: () => toggleUnCancelReservation(),
    },
    {
      id: "checkInBtn",
      title: t("Check In"),
      variant: "outline-success",
      className: "",
      icon: "fa-solid fa-sign-in",
      ifSelectedItem: true,
      reservationCode: ["C", "N", "T", "P"],
      onClick: () => {
        toggleAddEdit("K");
      },
    },
    {
      id: "cashierBtn",
      title: t("Cashier"),
      variant: "outline-success",
      className: "",
      icon: "fa-solid fa-cash-register",
      ifSelectedItem: false,
      reservationCode: [],
      onClick: () => {
        toggleCashier();
      },
    },
    {
      id: 6,
      title: t("Undo Check-In"),
      variant: "outline-secondary",
      className: "",
      icon: "fa-solid fa-sign-out",
      ifSelectedItem: true,
      reservationCode: ["I", "P"],
      onClick: () => {
        toggleUndoChekIn();
      },
    },
    {
      id: "assignRoom",
      title: t("Assign Room"),
      variant: "outline-secondary",
      className: "",
      icon: "fa-solid fa-tent-arrow-down-to-line",
      ifSelectedItem: true,
      reservationCode: ["T", "C", "I", "H", "P"],
      onClick: () => {
        toggleShowAdvancedRoom();
      },
    },
    {
      id: 8,
      title: t("Re Check-In"),
      variant: "outline-secondary",
      className: "",
      icon: "fa-solid fa-sign-in",
      ifSelectedItem: true,
      reservationCode: [],
      onClick: () => {
      },
    },
    // {
    //   id: 9,
    //   title: t("Bump Out"),
    //   variant: "outline-secondary",
    //   className: "",
    //   icon: "fa-solid fa-hotel",
    //   ifSelectedItem: true,
    //   reservationCode: ["C", "I"],
    //   onClick: () => {
    //   },
    // },

    {
      id: 10,
      title: t("Undo Checkout"),
      variant: "outline-secondary",
      className: "",
      icon: "fas fa-door-closed",
      ifSelectedItem: true,
      reservationCode: ["O"],
      onClick: () => {
        toggleUndoChekout();
      },
    },
    {
      id: 11,
      title: t("View"),
      variant: "outline-secondary",
      className: "",
      icon: "fas fa-eye",
      ifSelectedItem: true,
      reservationCode: ["T", "C", "I", "H", "P", "B", "N", "X", "O", "U"],
      onClick: () => toggleAddEdit("VIEW"),
    },
  ];
  const [showAdvancedRoom, setShowAdvancedRoom] = useState(false);
  const toggleShowAdvancedRoom = () => {
    setShowAdvancedRoom(!showAdvancedRoom);
    if (showAdvancedRoom) {
      //MEans is going away
      setReloadData(true);
    }
  };

  const toggleUndoChekIn = () => {
    setShowUndoCheckIn(!showUndoCheckIn);

    if (!showUndoCheckIn) {
      setReloadData(true);
    }
  };

  const [showUndoCheckout, setShowUndoCheckout] = useState(false);
  const toggleUndoChekout = () => {
    setShowUndoCheckout(!showUndoCheckout);

    if (!showUndoCheckout) {
      setReloadData(true);
    }
  };

  const [showCashier, setShowCashier] = useState(false);
  const toggleCashier = () => {
    // console.log("Res", selectedItem);
    if (selectedItem?.ReservationCode === "X") {
      confirmAlert({
        //   title: 'Warning',
        message: t("This reservation is cancelled."),
        buttons: [
          {
            label: "Ok",
            onClick: () => { },
          },
        ],
      });
    } else {
      setShowCashier(!showCashier);
    }
  };

  const [showShareRoom, setShowShareRoom] = useState(false);
  const toggleShareRoom = () => {
    setShowShareRoom(!showShareRoom);
  }

  const [showBumpoutManagement, setShowBumpoutManagement] = useState(false);
  const toggleBumpOutManagement = async () => {
    const { ArrivalDate } = await bindDataDetailsRemoteForCheckoutValidation(selectedItem);
    if (moment(ArrivalDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
      toast({
        type: "error",
        message: t("You can only bump-out on the day of arrival."),
        width: "auto",
        height: "auto",
      })
      return;
    }
    setShowBumpoutManagement(!showBumpoutManagement);
    if (showBumpoutManagement) {
      setReloadData(true);
    }
  }
  return (
    <>
      {showBumpoutManagement && (
        <BumpOutAddEdit toggle={toggleBumpOutManagement}
          toggleAddEdit={toggleAddEdit}
          modalSize="lg"
          reservation={selectedItem} />
      )}
      {/* {showShareRoom && (
        <ShareRoom toggle={toggleShareRoom}
          modalSize="lg"
          reservation={selectedItem} />
      )} */}
      {showUndoCheckIn && (
        <UndoCheckIn
          show={showUndoCheckIn}
          toggle={toggleUndoChekIn}
          reservation={selectedItem}
        />
      )}
      {showUndoCheckout && (
        <UndoCheckout
          show={showUndoCheckout}
          toggle={toggleUndoChekout}
          reservation={selectedItem}
        />
      )}
      {showCashier && (
        <CashierModal
          show={showCashier}
          setShow={setShowCashier}
          reservation={selectedItem}
        />
      )}

      {showCancelReservation ? (
        <CancelReservation
          reservation={selectedItem}
          show={showCancelReservation}
          toggle={toggleCancelReservation}
        />
      ) : null}
      {showUnCancelReservation ? (
        <UnCancelReservation
          reservation={selectedItem}
          show={showUnCancelReservation}
          toggle={toggleUnCancelReservation}
        />
      ) : null}
      <ModalColorPicker
        show={showColorSchema}
        handleClose={() => toggleColorScheme()}
      />
      <Breadcrumbs pathTitle={"Front-Desk / Front-Desk / Reservation"} />
      <Container>
        <div className="row mx-0">
          <div className="col-12">
            <div className="d-flex flex-wrap align-items-center justify-content-between mb-2 ">
              {isLoading ? (
                <>
                  <div className="">
                    <div
                      className="w-25 mx-auto my-1"
                      style={{ paddingTop: "" }}
                    >
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">
                          {t("Loading")}...
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex align-items-center my-1">
                    {/* <i className={section.FormIcon + " me-3 fa-2x"}></i> */}
                    <i className="fa-solid fa-home me-3 fa-2x" />
                    <div className="d-flex flex-column">
                      <h5 className={"my-1"}>{t("Reservation")}</h5>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              className="ps-3 py-2 z-2"
              style={{ position: "sticky", top: 0, backgroundColor: "#f2f2f2" }}
            >
              <div className="d-flex w-100 my-2 mt-0 justify-content-start gap-1">
                {principalButtonList.map((item) => {
                  // console.log(selectedItem);
                  if (item.ifSelectedItem && selectedItem) {
                    //Validate if checking is pressed
                    if (item.id === "checkInBtn" && !selectedItem.IdRoom) {
                      return <></>;
                    }
                    // if (item.id === "assignRoom" && selectedItem.IdRoom) {
                    //   return <></>;
                    // }

                    return item.reservationCode.length > 0 &&
                      item.reservationCode.includes(
                        selectedItem.ReservationCode
                      ) ? (
                      <>
                        <Button
                          key={item}
                          variant={item.variant}
                          size="lg"
                          className={item.className}
                          onClick={item.onClick}
                        >
                          {t(item.title)}
                          <br />
                          <i className={item.icon} />
                        </Button>
                      </>
                    ) : (
                      <></>
                    );
                  } else if (!item.ifSelectedItem) {
                    return (
                      <>
                        <Button
                          key={item}
                          variant={item.variant}
                          size="lg"
                          className={item.className}
                          onClick={item.onClick}
                        >
                          {t(item.title)}
                          <br />
                          <i className={item.icon} />
                        </Button>
                      </>
                    );
                  } else return <></>;
                })}
              </div>
              <div className="d-flex w-100 my-2 justify-content-start gap-1">
                <ButtonGroup size="lg">
                  {secondeButtonList.map((item, index) => {
                    if (item.requireSelection) {
                      if (selectedItem) {
                        if (!selectedItem.IdRoom && item.id === "releaseRoom") {
                          return <></>;
                        }

                        return item.reservationCode.length > 0 &&
                          item.reservationCode.includes(
                            selectedItem.ReservationCode
                          ) ? (
                          <>
                            <Button
                              key={item}
                              variant="outline-secondary"
                              size="md"
                              onClick={item.onClick}
                            >
                              {t(item.name)}
                            </Button>
                          </>
                        ) : (
                          <></>
                        );
                      } else {
                        return null;
                      }
                    } else {
                      return (
                        <Fragment key={index}>
                          <Button
                            variant="outline-secondary"
                            size="md"
                            onClick={item.onClick}
                          >
                            {t(item.name)}
                          </Button>
                        </Fragment>
                      );
                    }
                  })}
                </ButtonGroup>
              </div>
            </div>
            {/* <ReservationFilter count={data?.length||0} filterApplied={(data) => setFilters(data)}/> */}
            {showReservationAvailability ? (
              <ReservationAvailability
                show={showReservationAvailability}
                handleClose={() => setShowReservationAvailability(false)}
                GUIDReservation={selectedItem?.GUID}
              />
            ) : null}
            {showRegistrationCard ? (
              <RegistrationCardModal
                show={showRegistrationCard}
                handleClose={() => setShowRegistrationCard(false)}
                GUIDReservation={selectedItem?.GUID}
              />
            ) : null}
            {showReservationForm ? (
              <ReservationFormModal
                show={showReservationForm}
                handleClose={() => setShowReservationForm(false)}
                GUIDReservation={selectedItem?.GUID}
              />
            ) : null}
            {show && (
              <ReservationDetails
                toggle={toggleAddEdit}
                show={show}
                actionMode={actionMode}
                selectedReservation={selectedItem}
                clearSelection={() => setSelectedItem(null)}
                handleClose={handleCloseReservationDetails}
              />
            )}
            {showAdvancedRoom ? (
              <AdvancedRoomSelection
                show={showAdvancedRoom}
                selectedReservation={selectedItem}
                toggle={toggleShowAdvancedRoom}
              />
            ) : null}
            <ReservationContainer
              showCancelReservation={showCancelReservation}
              showUnCancelReservation={showUnCancelReservation}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              showAddEdit={show}
              reloadData={reloadData}
              setReloadData={setReloadData}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default ReservationPage;
